@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap);

* {
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  font-family: sans-serif;
  background-color: #EEE;
}
